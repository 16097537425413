import { useState, useRef, useEffect } from 'react';
import VimeoPlayer from '@/components/VimeoPlayer/VimeoPlayer';
import IntersectionObserverElement from '@/components/IntersectionObserverElement/IntersectionObserverElement';

export default function VideoSectionFlex() {
    const [sectionVisible, setSectionVisible] = useState<any | null>(null);
    const elementRef = useRef<HTMLDivElement | null>(null);
    const playerRef = useRef<any>(null);

    useEffect(() => {
        // When the section becomes visible, start a 3-second timer
        if (sectionVisible) {
            const timer = setTimeout(() => {
                if (playerRef.current) {
                    playerRef.current.play();
                }
            }, 3000);

            return () => clearTimeout(timer);
        }
        if (!sectionVisible && playerRef.current) {
            playerRef.current.pause();
        }
    }, [sectionVisible]);

    return (
        <section className='relative flex items-center pb-0 sm:pb-10 pt-12 sm:pt-[116px] px-4 md:px-10 lg:px-12 lx:px-[70px]'>
            <IntersectionObserverElement updateElementIsVisible={setSectionVisible} classNames='' ref={elementRef} />

            {/* blue effect */}
            <div className='absolute left-[-500px] rounded-full opacity-60 bg-[#1cc8e1] bg-opacity-[0.4] blur-[137px] w-[729px] h-[575px] z-[1] ' />
            <div className='container max-w-[1300px] mx-auto'>
                <div className='grid lg:grid-cols-2 xl:grid-cols-[minmax(auto,500px)_1fr] gap-4 lg:gap-0 items-center relative z-[2]'>
                    {/* Left Column - Text Content */}
                    <div className='max-w-full lg:max-w-[500px]'>
                        <span className='text-[#00E5FF] text-2xl sm:text-[24px] font-semibold tracking-wide'>Success Stories</span>

                        <h1 className='text-white text-2xl sm:text-3xl md:text-[42px] font-semibold  md:leading-[3.25rem] max-w-full sm:max-w-[400px] mb-0 mt-0'>
                            Real Impact On Kids Math Skills
                        </h1>

                        <h2 className='text-white text-xl md:text-3xl font-medium leading-snug'>Learning Math Minus the Anxiety</h2>

                        <p className='text-white opacity-[0.99] text-base md:text-[20px] leading-[24px] sm:leading-[30px] max-w-[500px]'>
                            Muzology takes the stress and anxiety out of learning one of the most important (and often hardest) subjects.
                        </p>

                        <p className='text-white opacity-[0.99] text-base md:text-[20px] leading-[24px] sm:leading-[30px] max-w-[500px] mt-6'>
                            Watch the video to see the difference Muzology can make for your kids too!
                        </p>
                    </div>

                    {/* Right Column - Image */}
                    <div>
                        <div className='max-w-[730px] ml-auto'>
                            <VimeoPlayer
                                videoId='1052420897'
                                onReady={(player) => {
                                    playerRef.current = player;
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
